.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-bottom: 1px solid #adabab;
    &-bold {
        font-weight: bold;
    }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 12px;
        font-size: 24px;
    }
    &-nav {
        display: flex;
        align-items: center;
        grid-gap: 6px;
    }
    &__link {
        text-decoration: none;
        font-size: 24px;
        color: #4f4d4d;
        &:hover {
            color: #11c4ec;
        }
        &--active {
            color: #11c4ec;
        }
    }
    &__li {
        padding-right: 6px;
        border-right: 2px solid #4f4d4d;
        cursor: pointer;
        &:last-child {
            border: none;
        }
    }
}
