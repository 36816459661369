.header-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:10px 9px 10px 30px;
  background: #D9D9D9;
  &__title{
    font-size: 30px;
    color: #00768F;
  }
}