.order-history{
  display: flex;
  padding:0 30px 25px 30px;
  flex-direction: column;
  grid-gap: 40px;
  border-bottom: 1px solid #ADABAB;
  &-row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__title{
    font-weight: bold;
    font-size: 40px;
    text-decoration-line: underline;
    color: #11C4EC;
    cursor: pointer;
  }
  &__progress{
    font-weight: bold;
    font-size: 40px;
    color: #11C4EC;
    text-decoration: none;
    &--success{
      color: #12CF47;
    }
    &--error{
      color: #EF3636;
    }
    &--green{
      color: #12CF47;
    }
    &--red{
      color: #EF3636;
    }
    &--lblue{
    color: #a0e2f1;
    }
    &--yellow{
      color: #f3c81bd9;
    }
  }
  &__date{
    font-size: 26px;
    color: #000000;
  }
  &-date{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  &__total{
    font-size: 32px;
    padding-right: 30px;
    width: 30%;
    text-align: right;
    &--bold{
      font-weight: bold;
    }
  }
}