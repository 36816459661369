.history{
  height: 100%;
  position: relative;
  &-info{
    height: calc(100% - 55px - 103px - 54px);
    overflow: auto;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    margin-right: 6px;
  }
}

.title {
  position: absolute;
  top: 120px;
  left: 500px;
}

.header-info-title {
  font-family: 'Hahmlet';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
}

.banner,
.overlay {
  position: absolute;
  top: 54px;
  right: 0;
  height: calc(100% - 54px);
}

.overlay {
  background: rgba(17, 131, 196, 0.45);
  width: 395px;
  padding: 30px 15px;
}

.side-title {
  font-family: 'Hahmlet';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  color: white;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: 1px;
}