.custom-checkbox {
    display: flex;
    align-items: center;
    font-size: 24px;

    .checkbox {
        width: 25px;
        height: 25px;
        box-shadow: 0 0 0 1px #000000;
        background-color: #ffffff;
        border-radius: 3px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .label {
        margin-left: 8px;
        word-break: break-word;
    }
    &--big{
        .checkbox{
            flex-shrink: 0;
            width: 30px;
            height: 30px;
        }
        .label{
            font-size: 13px;
            margin-left: 15px;
            font-weight: bold;
        }
    }
}
