.sub-header{
  padding: 8px 15px 22px;
  &__img{
    max-width: 220px;
    width: 100%;
  }
  &--big{
    padding: 34px 15px 74px;
    border-bottom: 1px solid #ADABAB;
    .sub-header__img{
      max-width: 687px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  &--pay{
    .sub-header__img{
      max-width: 385px;
    }
  }
}