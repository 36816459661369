.errorpage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 40px;

    &__logo {
        margin: 0 0 40px 0;
    }

    &__text {
        width: 800px;
        line-height: 124%;
        text-align: center;
    }
    &__btns {
        display: flex;
        width: 600px;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 0 0;
    }
    &__back {
        background: #727272;
        border: none;
        border-radius: 15px;
        box-shadow: 0 0 0 1px #fff;
        color: #fff;
        cursor: pointer;
        font-size: 24px;
        line-height: 1.1;
        outline: none;
        padding: 13px 27px;
        margin: 30px 0 0 0;
    }
    &__forgot {
        background: #11c4ec;
        border: none;
        border-radius: 15px;
        box-shadow: 0 0 0 1px #fff;
        color: #fff;
        cursor: pointer;
        font-size: 24px;
        line-height: 1.1;
        outline: none;
        padding: 13px 27px;
        margin: 30px 0 0 0;
    }
}
