.castom-button{
    font-size: 20px;
    background: #11C4EC;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: 0 0 0 1px #000000;
    border-radius: 11px;
    font-weight: bold;
    padding:6px 28px;

    &:hover{
      background: #2ca2bb;
    }
  &:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }
  &--white{
    color: #F9F2F2;
    padding:6px 33px;
  }
  &--cancel{
    background: #727272;
    color: #F9F2F2;
    padding:6px 33px;
  }
  &--cancel:hover{
    background: #525252;
  }
  &--small{
    padding: 6px 22px;
  }
  &--default{
    width: 190px;
  }
  &--big{
    font-size: 28px;
    width: 230px;
    padding: 8px 28px;
  }
}