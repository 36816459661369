.product {
    position: relative;
    padding: 0 5px 0 20px;
    border-bottom: 1px solid #adabab;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 41px;
    &-ul {
        margin-bottom: 15px;
        font-size: 20px;
        color: #000000;
    }
    &__desc {
        margin-bottom: 8px;
    }
    &__title {
        font-size: 24px;
        font-weight: bold;
    }
    &__blue {
        color: #11c4ec;
        font-weight: bold;
    }
    &-info {
        max-width: 350px;
        width: 100%;
        font-size: 16px;
        color: #5c5b5b;
    }
    &-picture {
        width: 219px;
        height: 205px;
    }
    &__img {
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    }
    &__thumbnail {
        position: absolute;
        width: 438px;
        left: 430px;
        z-index: 1;
        top: 0;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    &-without_picture {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__svg {
        width: 170px;
        fill: #afafaf;
    }
    &__img_none {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;
        color: #000000;
    }
    &-head {
        margin-bottom: 25px;
    }
    &__head {
        font-size: 22px;
        font-weight: bold;
    }
    &-select {
        max-width: 734px;
        width: 100%;
        padding-right: 7px;
        padding-left: 20px;
    }
    .cost {
        border-left: 1px solid black;
        border-right: 1px solid black;
        color: #11c4ec;
        font-family: 'Inria Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
    }
    &__sub_title {
        margin-bottom: 8px;
        display: flex;
        &--big {
            margin-bottom: 25px;
        }
    }
    &-size {
        width: 210px;
    }
    &-selected {
        width: 76px;
        text-align: center;
    }
    &__coast {
        color: #11c4ec;
        border-left: 2px solid #000000;
        &--last {
            border-right: 2px solid #000000;
        }
    }
    &-total {
        font-size: 22px;
        font-weight: bold;
        display: flex;
        grid-gap: 80px;
        margin-bottom: 15px;
    }
    &-footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__status {
        font-size: 16px;
        color: #12cf47;
        margin-bottom: -10px;
        &--error {
            color: #ff0000;
        }
        &--success {
            color: #12cf47;
        }
    }
    &__saved {
        position: absolute;
        color: #12cf47;
        font-size: 18px;
        top: -30px;
        right: 66px;
    }
    &__close {
        height: 100%;
    }
    &__svg_close {
        padding-right: 10px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        &:hover {
            transform: scale(1.2);
        }
    }
    &-main {
        display: flex;
        grid-gap: 13px;
        &--center {
            align-items: center;
        }
        .product-picture {
            flex-shrink: 0;
            width: 219px;
            height: 205px;
        }
    }
    &--small {
        .product-total {
            margin-bottom: 5px;
        }
        .product-footer {
            justify-content: flex-end;
            margin-bottom: 10px;
        }
    }
}
