.contact {
    &-info {
        font-size: 30px;
        color: #000000;
        padding: 10px 15px 20px 15px;
        border-bottom: 1px solid #adabab;
    }
    &-text {
        max-width: 1140px;
        width: 100%;
    }
    &__link {
        font-weight: bold;
    }
    &-form {
        overflow: auto;
        height: calc(100% - 55px - 103px - 50px - 91px);
        padding: 30px 25px 34px;
        margin-right: 6px;
    }
    &-field {
        min-width: 1190px;
        padding-right: 190px;
        width: 100%;
        display: flex;
        grid-gap: 34px;
        margin-bottom: 34px;
        align-items: flex-start;
        &__text-area {
            width: 100%;
            height: 300px;
            padding: 9px;
            line-height: 1.5;
            font-size: 21px;
            border-radius: 19px;
            background-color: #d9d9d9;
            border: none;
        }

        .red {
            box-shadow: 0 0 0 1px red;
        }
        &--margin-bot {
            margin-bottom: 15px;
        }
        &__title {
            font-size: 30px;
            font-weight: bold;
            width: 375px;
        }
    }
    &-button {
        display: flex;
        justify-content: flex-end;
    }
}

.error {
    text-align: left;
    color: red;
    margin-bottom: 10px;
}

.df {
    display: flex;
    flex-direction: column;
    width: 100%;
}
