.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(121, 119, 119, 0.7);
    z-index: 3;
}

.wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    padding: 30px 40px;
    width: 100%;
    max-width: 667px;
    height: 26vh;
    background: white;
    z-index: 4;
}

.wrapperImage {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    width: 100%;
    max-width: 667px;
    height: auto;
    background: white;
    z-index: 4;
}

.image {
    width: 100%;
    object-fit: cover;
}

.closeBtn {
    position: absolute;
    top: 18px;
    right: 25px;
    font-size: 37px;
    cursor: pointer;
    transition: all 0.2s;
    color: rgb(64, 64, 64);
}

.closeBtn:hover {
    transform: scale(1.2);
    transition: all 0.2s;
}

.message {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
}

.btns {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.jcc {
    justify-content: center;
}

.error {
    text-align: left;
    color: red;
    margin-bottom: 10px;
}
