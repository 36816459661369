@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
.App{
  height: 100%;
  font-family: 'Inria Sans', sans-serif;

}

::-webkit-scrollbar{
  width: 27px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #ffffff 38%, #ADABAB 38%, #ADABAB 58%, #ffffff 58%);
}

::-webkit-scrollbar-thumb {
  background: url('assets/arrow-up.svg'),url('assets/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center 2%,center 98%;
  background-color: #11C4EC;
  outline: none;
  border-radius: 14.5px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: unset;
  background-color: #11C4EC;
}