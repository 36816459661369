.changePassword{
    &-info{
      font-size: 30px;
      color: #000000;
      padding: 10px 15px 20px 15px;
      border-bottom: 1px solid #ADABAB;
    }
    &-text{
      max-width:1140px;
      width: 100%;
    }
    &__link{
      font-weight: bold;
    }
    &-form{
        display: flex;
        flex-direction: column;
        align-items: center;
      overflow: auto;
      height: calc(100% - 55px - 103px - 50px - 91px);
      padding: 30px 25px 15px;
      margin-right: 6px;
    }
    &-field{
      padding-right: 190px;
      width: 1000px;
      display: flex;
      grid-gap: 34px;
      margin-bottom: 34px;
      align-items: baseline;
      height: 87px;
      &--margin-bot{
        margin-bottom: 15px;
      }
      &__title{
        font-size: 30px;
        font-weight: bold;
        width: 475px;
      }
    }
    &-button{
        margin-left: 130px;
      display: flex;
      justify-content: flex-end;
    }
  }
  
  .error {
    text-align: left;
    color: red;
    margin-bottom: 10px;
  }
  
  .df {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .eye{
        position: absolute;
        width: 40px;
        right: 15px;
        top: 5px;
        cursor: pointer;
    }
  }