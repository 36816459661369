.custom-input {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    &__input {
        width: 98%;
        height: 34px;
        padding: 9px;
        line-height: 1.5;
        font-size: 21px;
        border-radius: 19px;
        background-color: #d9d9d9;
        border: none;
        &:disabled {
            cursor: pointer;
        }

        &.red {
            box-shadow: 0 0 0 1px red;
        }

        &.password {
            padding: 12px 79px 12px 12px !important;
            width: 84%;
        }

        &.search {
            height: 45px;
        }
    }
    &--white {
        .custom-input__input {
            background-color: #fff;
            line-height: 1.5;
            padding: 12px;
            font-size: 28px;
        }
    }

    &--search {
        position: relative;
        .custom-input__input {
            box-sizing: border-box;
            font-size: 24px;
            padding: 10px 10px 10px 60px;
            box-shadow: none;
        }
        .custom-input__icon {
            position: absolute;
            left: 17px;
            top: 12px;
        }
    }
    &--small {
        .custom-input__input {
            width: 90%;
            margin: 0 auto;
            font-size: 22px;
        }
    }

    .error-text {
        color: red;
        margin: 10px 0 0 4px;
        height: 20px;
    }
}

.qtyInput {
    padding: 0 !important;
    text-align: center;
}
