.sweet-loading {
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 10;
    left: 0;
    width: 100vw;
    background: black;
    opacity: 0.4;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
