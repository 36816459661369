.login {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-wrapper {
        margin-bottom: 20px;
        height: 134px;
        &--big {
            margin-bottom: 40px;
        }
        .password-wrapper {
            position: relative;
            .eye {
                position: absolute;
                width: 40px;
                right: 15px;
                top: 10px;
                cursor: pointer;
            }
        }
    }
    &-forget {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 9px;
    }
    &-hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__logo {
        max-width: 100%;
        margin-bottom: 18px;
    }
    &__title {
        font-size: 40px;
        margin-bottom: 30px;
    }
    &__adrees {
        font-size: 24px;
        max-width: 600px;
        width: 100%;
        color: #393838;
    }
    &__link {
        color: #393838;
        text-decoration: none;
    }
    &__form {
        background: #d9d9d9;
        border: 3px solid #000000;
        padding: 24px 46px;
        max-width: 600px;
        width: 100%;
        margin-bottom: 9px;
    }
    &--marginMin {
        margin-bottom: 15px;
    }
    &__label {
        font-size: 32px;
        &--margin {
            padding-bottom: 5px;
        }
    }
    &__forgot {
        color: #393838;
        font-size: 18px;
        cursor: pointer;
    }
    &__forgot:hover {
        color: #11c4ec;
        text-decoration: underline;
    }
    &__button {
        background: #11c4ec;
        box-shadow: 0 0 0 1px #ffffff;
        border-radius: 15px;
        font-size: 24px;
        color: #ffffff;
        width: 112px;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 10px 0;
        line-height: 1.1;
        &:hover {
            transform: scale(1.1);
        }
    }
    &-btn {
        display: flex;
        justify-content: flex-end;
    }
}
